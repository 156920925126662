import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

//{ sendToken: true } sulle chiamate che vogliono l'autenticazione

export const ScrutiniAPI = {

    getElencoClassiRiepilogoDidattico: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/classiPerElencoAlunni`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getSchedaAlunno: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/schedaAlunno`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getElencoAlunniRiepilogoDidattico: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/elencoAlunni`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getRegistroDidatticoMateria: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registroDidatticoMateria/`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    editMoltiplicatore: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registroDidatticoMateria/editMoltiplicatore`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getPermessiStatoRegistroDidattica: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registroDidatticoMateria/getPermessiStato`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getPermessiStatoRegistroDidatticaTO: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registroDidatticoMateria/getPermessiStatoTO`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getPermessiStatoElencoAlunni: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/getPermessiStato`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    editVotoProposto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registroDidatticoMateria/editVotoProposto`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getVotoProposto: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/registroDidatticoMateria/getVotoProposto`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    editCondottaTutor: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/editCondottaTutor`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getVotiPrescrutinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/getVotiPrescrutinio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    savePrescrutinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/saveVotiPrescrutinio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    avviaScrutinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/nuovoScrutinio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    concludiScrutinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/concludiScrutinio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    riaperturaScrutinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/riaperturaScrutinio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    saveVotiScrutinio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/saveVotiScrutinio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    recuperoVotiScrutinioAlunno: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/getVotiScrutinio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    calcolaCrediti: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/gestioneDidatticaClasse/calcoloCrediti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getDocumentiScrutini: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/getDocumentiScrutinio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getElencoCollegioFormatori: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneDidatticaClasse/getCollegio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    savePresenzeCollegioFormatori: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `gestioneDidatticaClasse/saveCollegio`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    //CREDITI FORMATIVI
    getElencoPercorsiFormazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/utility/elencoPercorsiFormazione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getMateriePercorsoFormazione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/creditiFormativi/getMateriePercorsoFormazione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    saveMateriePercorsoIstruzione: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/creditiFormativi/saveMateriePercorsoFormazione`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    getOldUfc: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/creditiFormativi/getCreditiUFCAnnoPassato`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },
    elencoPercorsiFormazioneAnno: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/creditiFormativi/elencoPercorsiFormazioneAnno`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data
    },

    
}
const cancelApiObject = defineCancelApiObject(ScrutiniAPI)