import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { Home } from "@mui/icons-material";

export default function DialogSelezionaAnnualita(props) {

    const annualitaList = [{ value: 1, name: '1° anno' }, { value: 2, name: '2° anno' }, { value: 3, name: '3° anno' }, { value: 4, name: '4° anno' }]
    const [annualitaSelected, setAnnualitaSelected] = useState(-1);
    const [isModalSelectAnnualitaOpen, setIsModalSelectAnnualitaOpen] = useState(true);

    useEffect(() => {
        if (props.isModalSelectAnnualitaOpen !== undefined) setIsModalSelectAnnualitaOpen(props.isModalSelectAnnualitaOpen);
    }, [props.isModalSelectAnnualitaOpen])

    useEffect(() => {
        if (props.annualitaSelected !== undefined) setAnnualitaSelected(props.annualitaSelected);
    }, [props.annualitaSelected])

    function changeAnnualita(e) {
        let annualitaTmp = e.target.value;
        setAnnualitaSelected(annualitaTmp);
    }
    function goHome() {
        if (props.goHome) props.goHome()
    }
    function confermaAnnualita() {
        if (props.confermaAnnualita) props.confermaAnnualita(annualitaSelected)
    }

    return (
        <Fragment>
            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xs" open={isModalSelectAnnualitaOpen}>
                <DialogTitle variant="h6">
                    <b>Seleziona annualità</b>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ pt: 0 }}>
                    <Typography my={2}>Selezionare l'annualità:</Typography>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="annualita-label">
                            Annualità
                        </InputLabel>
                        <Select
                            data-testid='sedeRicercaSelect'
                            label="Annualità"
                            labelId="annualita-label"
                            value={annualitaSelected}
                            onChange={changeAnnualita}
                        >
                            <MenuItem
                                key={-1}
                                value={-1}
                            >
                                ...
                            </MenuItem>
                            {annualitaList.map((annualita) => (
                                <MenuItem
                                    key={annualita.value}
                                    value={annualita.value}
                                >
                                    {annualita.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
                        <Button
                            onClick={confermaAnnualita}
                            fullWidth
                            disabled={annualitaSelected === -1}
                            data-testid='confermaAnnualitaBtn'
                            variant="contained"
                            color="success">
                            Conferma e prosegui
                        </Button>
                        <Button
                            sx={{ mt: 1 }}
                            onClick={goHome}
                            fullWidth
                            startIcon={<Home />}
                            data-testid='confermaAnnualitaBtn'
                            variant="contained"
                            color="primary">
                            Torna alla home
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Fragment >
    )
}