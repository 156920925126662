import { Alert, Box, Button, CircularProgress, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react"
import { ScrutiniAPI } from "../../api/scrutiniApi";
import { ArrowBack, Home } from "@mui/icons-material";

export default function SelectPercorsiIstruzione(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [listPercosiFormativi, setListPercorsiFormativi] = useState([]);
    const [idPercorsoSelezionato, setIdPercorsoSelezionato] = useState(-1)
    const [nomePercorsoSelezionato, setNomePercorsoSelezionato] = useState("");
    const [alertMsg, setAlertMsg] = useState("");
    useEffect(() => {
        if (props.annoFormativo !== undefined && props.annoFormativo !== null && props.annualitaSelected !== undefined &&  props.annualitaSelected !== -1) {
            getElencoPercorsiFormazione(props.annoFormativo, props.annualitaSelected);
        }
    }, [props.annoFormativo, props.annualitaSelected])

    useEffect(() => {
        setIsLoading(false);
    }, [listPercosiFormativi]);

    function goHome() {
        if (props.goHome)
            props.goHome();
    }

    function goSelezionaAnnualita() {
        if (props.goSelezionaAnnualita)
            props.goSelezionaAnnualita();
    }

    function changeIdPercorsoSelezionato(idCorso, nomePercorso) {
        setIdPercorsoSelezionato(idCorso);
        setNomePercorsoSelezionato(nomePercorso)
    }
    function confermaPercorso() {
        if (props.confermaPercorso)
            props.confermaPercorso(idPercorsoSelezionato, nomePercorsoSelezionato);
    }

    async function getElencoPercorsiFormazione(annoFormativoTmp, annualitaSelectedTmp) {
        let dataObj = {
            annoFormativo: annoFormativoTmp,
            annualita: annualitaSelectedTmp
        }
        ScrutiniAPI.elencoPercorsiFormazioneAnno(dataObj)
            .then((result) => {
                if (showError === true) setShowError(false);
                setListPercorsiFormativi([...result]);
            })
            .catch(function (error) {
                setIsLoading(false);
                setShowError(true);
                 let errorMsg = "Errore durante il recupero dei dati. Riprovare tra qualche minuto."
                if (error?.response?.status === 409) {
                errorMsg = error?.response?.data?.data[0]?.msg ?? "Per l'annualità selezionata non è presente alcun corso."
                }
                setAlertMsg(errorMsg);
            })
    }


    return (
        <Fragment>
            {isLoading ? (
                <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress sx={{ mt: 5 }} />
                    <Typography sx={{ mt: 3, width: 'fit-content' }}>
                        Caricamento in corso...
                    </Typography>
                </Box>
            ) : (
                showError ? (
                    <Box>
                        <Alert sx={{ mt: 2 }} severity="error">{alertMsg}</Alert>
                        <Button fullWidth variant="outlined" sx={{ mt: 2 }} onClick={goSelezionaAnnualita} startIcon={<ArrowBack />} >Torna a seleziona annualità</Button>
                        <Button variant="contained" sx={{ mt: 1 }} onClick={goHome} startIcon={<Home />} fullWidth >Torna alla home</Button>
                    </Box>
                ) : (
                    <Fragment>
                        {listPercosiFormativi.length > 0 ? (
                            <Box maxHeight={"60vh"} style={{ overflow: "scroll" }}>
                                <Table>
                                    <TableBody>
                                        {listPercosiFormativi.map((percorso, index) => (
                                            <TableRow key={index} sx={{ cursor: 'pointer' }} onClick={() => changeIdPercorsoSelezionato(percorso?.idPercorsoFormazione, percorso?.nomePercorsoFormazione)}>
                                                <TableCell sx={{ minWidth: '200px', background: (percorso?.idPercorsoFormazione === idPercorsoSelezionato) ? '#1976D2' : 'white', color: (percorso?.idPercorsoFormazione === idPercorsoSelezionato) ? 'white' : 'black' }}>
                                                    {percorso?.nomePercorsoFormazione}  {(percorso?.idPercorsoFormazione === idPercorsoSelezionato) ? "- SELEZIONATO" : ""}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        ) : (
                            <Box>
                                <Alert sx={{ mt: 2 }} severity="info">Nessun percorso formativo disponibile.</Alert>
                                <Button variant="contained" sx={{ mt: 2 }} onClick={goHome} startIcon={<Home />} fullWidth >Torna alla home</Button>
                            </Box>

                        )}
                        <Box>
                            <Button sx={{ mt: 2 }} fullWidth variant="contained" color="success"
                                onClick={confermaPercorso} disabled={idPercorsoSelezionato === -1}>
                                Conferma e prosegui
                            </Button>
                            <Button fullWidth variant="outlined" sx={{ mt: 1 }} onClick={goSelezionaAnnualita} startIcon={<ArrowBack />} >Torna a seleziona annualità</Button>
                            <Button fullWidth variant="contained" sx={{ mt: 1 }} onClick={goHome} startIcon={<Home />} >Torna alla home</Button>
                        </Box>
                    </Fragment>
                )
            )}
        </Fragment>
    )
}